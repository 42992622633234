import React from "react";
import "../Portfolio/Portfolio.css";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';


function PortfolioApp() {
    return (
        <div className="portfolioapp">
            <Container>
                <Row className="ports">
                    <Col>
                        <div>
                            <h1>Welcome to Digital Triumph, a leading IT company that specializes in providing cutting-edge solutions for websites, e-commerce sites, custom ERP, and CRM applications. With a team of skilled professionals and a passion for innovation, we strive to deliver high-quality products and services that cater to our clients' unique business needs. Our portfolio showcases some of the remarkable projects we have successfully delivered, reflecting our commitment to excellence and customer satisfaction.</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="portfolioapprow">
                    <Col lg={6} className="portfolioappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/pridegen2.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/pridegen3.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Pridegen4.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>


                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>PRIDEGEN</h1>
                            <p>PrideGen is a mobile application for the LGBTQ+ community's to connect, thrive and to
                                forge connections and empower identites.</p>
                        </div>
                    </Col>
                </Row>


                <Row className="portfolioapprow" id="itreverserow">
                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>CARS AND LOANS</h1>
                            <p>Cars and Loans is a mobile application for a hassle-free third party car insurance,
                                simplifying the process with ease, ensuring your vehicle is protected.</p>
                        </div>
                    </Col>

                    <Col lg={6} className="portfolioappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/carsnloans.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/carsnloans1.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/carsnloans2.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>


                <Row className="portfolioapprow">
                    <Col lg={6} className="portfolioappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Menly1.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Menly2.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Menly3.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Menly4.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/Menly5.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>


                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>MENLY CLOTHING</h1>
                            <p>Menly is a mobile application that caters to the needs of men's clothing with a carefully
                                curated collection of trendy outfits and timeless essentials, all at your fingertips.</p>
                        </div>
                    </Col>
                </Row>

                <Row className="portfolioapprow">
                    
                    <Col lg={6} className="portfoliocol1">
                        <div>
                            <h1>Medleiz</h1>
                            <p>Book services effortlessly with our app,
                                Easily connect with local service providers through our user-friendly app. Book appointments, track service history, and make secure payments all in one place. Enhance your experience with real-time updates, customer reviews, and personalized recommendations. Simplify your life with seamless access to top-rated professionals.</p>
                        </div>
                    </Col>

                    <Col lg={6} className="portfolioappcol">
                        <Carousel fade>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/med1.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/med2.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/med3.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/med4.png")} />
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                                <img width="80%" src={require("../../Assests/Portfolioappimages/med5.png")} />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PortfolioApp;